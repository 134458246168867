.container {
  min-height: 0 !important;

  .gridItem {
    transition: 0.1s ease-in-out;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.4);

    padding: 32px 32px 32px 32px;

    :global(.et-icon-box-title) {
      color: var(--text-color1);
    }

    :global(.et-icon-box-content) {
      color: #616161;
    }

    :global(.et-icon) {
      // color: var(--text-color1);
      filter: grayscale(0.75);
      // border-radius: 0px;
    }

    :global(.ghost-icon) {
      color: var(--text-color1);
    }

    &.gridItemDark {
      background-color: var(--color4);
      &:hover {
        background-color: var(--color-hover4);
      }
    }
    &.gridItemRed {
      background-color: var(--color1);
      &:hover {
        background-color: var(--color-hover1);
      }
    }
  }
}
