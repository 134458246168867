.ReactQueryDevtools {
  code,
  pre {
    color: initial;
  }
}

@media only screen and (min-width: 1280px) {
  .container,
  .wpml-ls-statics-post_translations,
  .header-menu > .mm-true > ul {
    max-width: 1200px;
  }
}

.header {
  z-index: 1070;
  &.sticky-true {
    position: sticky;
    position: -webkit-sticky;
    top: -62px;
  }
}

.button {
  color: var(--text-color1);
  // font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  transition: all 0.5s ease-in-out;
  &:hover {
    // color: #ffffff !important;
    color: var(--text-color1);
    background-color: var(--button-color-hover1);
  }
}

// .form-control {
//   border-radius: 0px;
//   color: var(--color2);
//   background-color: #ffffff;
//   border-color: var(--color3);
// }

// input,
// input[type='date'],
// input[type='datetime'],
// input[type='datetime-local'],
// input[type='email'],
// input[type='month'],
// input[type='number'],
// input[type='password'],
// input[type='search'],
// input[type='tel'],
// input[type='text'],
// input[type='time'],
// input[type='url'],
// input[type='week'],
// input[type='file'] {
//   color: var(--color2);
//   background-color: #ffffff;
//   border-color: var(--color3);
// }

button {
  &.btn-sm {
    padding: 4px 8px;
  }

  &.btn:focus,
  &.btn.focus {
    box-shadow: inherit;
  }

  &.default-button {
    color: inherit;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: inherit;

    &:hover {
      color: inherit !important;
      background-color: inherit;
    }
  }

  &.remove {
    border: none;
    padding: 0.25rem;
  }
}
a[disabled] {
  pointer-events: none;
  text-decoration: line-through;
  cursor: not-allowed;
}

/* Remove outline on the forms and links */
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.custom-select:focus {
  box-shadow: none;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

.product {
  .single-product-wrapper {
    .summary {
      box-shadow: initial;
      border-radius: initial;
    }
  }
}

.modal-content {
  // background-color: var(--color-hover5);
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 10px 0px;

  .modal-header {
    border-bottom: 1px solid var(--color5);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: var(--color1);
    }

    button.close {
      display: inline-block !important;
      border: none;
      padding: 0.375rem 0.75rem;
      font-size: 2rem;
      line-height: 2rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: var(--color1);
      background-color: transparent;
    }
  }

  .modal-body {
    input {
      // background-color: var(--color4);
      outline: none;
      &::placeholder {
        color: var(--color-hover1);
      }
      &:focus {
        border-color: var(--color1);
      }
    }
  }
}

// .modal-header button.close {
//   line-height: normal;
//   display: inline-block !important;
//   // padding: 0.5rem 0.75rem;
//   margin: -0.5rem;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   border-radius: 0.25rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   color: #212529;
//   background-color: $color2;
//   border-color: $color2;
//   margin: 0.25rem;
// }

// fix close modal button size
@media only screen and (max-width: 479px) {
  // button:not(.dgwt-wcas-search-submit):not(.plyr__control):not(.slick-arrow):not(.wooscp-btn) {
  button.theme-button {
    width: inherit;
    display: inherit;
    margin-right: inherit;
    margin-left: inherit;

    &:not(.close),
    &:not(.owl-prev),
    &:not(.owl-next) {
      width: 100%;
      display: inline-block;
      // display: block !important;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .modal {
    button.close {
      width: initial !important;
    }
  }

  .Toastify {
    button {
      width: initial !important;
      padding: 4px 8px;
    }
  }
}

input {
  &.custom {
    width: 100%;
    border-radius: 4px;
    border-width: 0px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #ced4da;
    /* height: calc(1.5em + 0.5rem + 2px); */
    font-size: 0.875rem;

    &.custom-sm {
      padding: 0.25rem 0.5rem;
    }
  }
}

select {
  // box-sizing: content-box;
  &.custom-select {
    box-sizing: border-box;
    height: calc(1.5em + 0.5rem + 0.5rem); // + 2px);
    padding: 0.25rem 0.5rem;
  }
}

// mobile header logo height
#header-logo-115569 {
  .logo,
  .sticky-logo {
    max-height: 35px;
  }
}
#header-logo-880392 .logo {
  max-height: 35px;
}

.react-images__container {
  button {
    color: #fff;
    background-color: transparent;
  }
}

.sticky-component {
  position: sticky;
  top: 58px;
  z-index: 79;

  @media only screen and (min-width: 1280px) {
    top: 138px;
  }
}

.vc_column_container {
  // width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dgwt-wcas-search-input {
  border-radius: 4px;
  height: inherit;
}
// block !importantbutton:not(.dgwt-wcas-search-submit):not(.plyr__control):not(.slick-arrow):not(.wooscp-btn):not(.close), button:not(.dgwt-wcas-search-submit):not(.plyr__control):not(.slick-arrow):not(.wooscp-btn) #product-ajax-loader

// @media only screen and (max-width: 479px) {
.owl-carousel {
  .owl-nav > {
    button.owl-prev,
    button.owl-next {
      display: inline-block !important;
      width: 48px !important;
      border: none !important;
    }
  }
}
// }

// .page-content-wrap {
//   // overflow-x: hidden;
//   // overflow-y: auto;
//   // height: 100vh;
// }
.d-shadow {
  box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 10px 0px !important;
}

.bg-color1 {
  background-color: var(--color1);
}
.bg-color2 {
  background-color: var(--color2);
}
.bg-color3 {
  background-color: var(--color3);
}
.bg-color4 {
  background-color: var(--color4);
}
.bg-color5 {
  background-color: var(--color5);
}
.text-patron {
  font-weight: 800 !important;
  color: var(--color1) !important;
  text-transform: uppercase !important;
  font-style: oblique;
}

//--------------------
hr {
  border-top: 1px solid $color-grey;
}

.card {
  border: 2px solid $color-mint-green;
}

.el-icon:before {
  display: inline-block;
  width: 48px;
  text-align: right;
}

.page-item {
  .page-link {
    color: $color-mint-green;
    background-color: $color-white;
    border: 1px solid $color-mint-green;
  }

  &.active .page-link {
    color: $color-white;
    background-color: $color-mint-green;
    border-color: $color-mint-green;
  }
}

.et-separator-89771 .line {
  border-bottom-color: $color-grey;
}

.separator-true .header-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link, .separator-true .sidebar-menu > .menu-item:not(.mm-true) .sub-menu .menu-item .mi-link, .separator-true.mm-container .sub-menu .menu-item .mi-link {
  padding: 8px 24px;
}